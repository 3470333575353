







































































































































































































































































































































































































































































































































.UnitEdit {
  .el-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    .el-form-item {
      width: 100%;
      margin-bottom: 22px;
    }
  }
   .el-upload {
  height: 180px !important;
}
.upload-btn {
  height: 180px;
}
}

